import {type Permission} from '@hconnect/apiclient'
import {NavItem} from '@hconnect/uikit/src/lib2'

import {useGetHrocLinks} from '../../hroc/routing'
import {useGetPerformanceDashboardLinks} from '../../performanceDashboard/routing'

export const useGetLinks = ({permissions}: {permissions: Permission[]}): NavItem[] => {
  const performanceDashboardLinks = useGetPerformanceDashboardLinks()
  const hrocLinks = useGetHrocLinks({permissions})

  return [...performanceDashboardLinks, ...hrocLinks]
}
