import {buttonOnBlueSx, CardBox} from '@hconnect/uikit/src/lib2'
import {ArrowForward} from '@mui/icons-material'
import Masonry from '@mui/lab/Masonry'
import {Box, Button, Collapse} from '@mui/material'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {generatePath, useNavigate} from 'react-router-dom'

import {useNavAccessContext} from '../../shared/hooks/useNavAccessContext'
import {CollapseContentContainer} from '../containers/CollapseContentContainer'
import {useTranslationPrefix} from '../hooks/useTranslationPrefix'
import {AREA_KPI_STATUS} from '../routing'
import {HierarchyNode, HierarchyNodeType} from '../types'

import {CountryItemHeader, LocationTitle} from './CountryItemHeader'
import {KilnStatusTable} from './KilnStatusTable'

type CollapseAreaItemProps = {
  handleOpen: (areaId: string) => void
  index: number
  isOpen: boolean
  area: HierarchyNode
  getUrl?: (locationId: string) => string
}

type KilnStatusBoxProps = {
  country: HierarchyNode
  id: string | number
}

export function KilnStatusBox({country, id}: KilnStatusBoxProps) {
  return (
    <CardBox data-test-id={`kiln-status-box-${id}`}>
      <Box display="flex" flexDirection="column">
        <CountryItemHeader
          countryData={{
            id: country.id,
            countryCode: country.countryCode,
            name: country.name
          }}
        />
        <KilnStatusTable plants={country.children} />
      </Box>
    </CardBox>
  )
}

export function CollapseAreaItem({area, handleOpen, index, isOpen}: CollapseAreaItemProps) {
  const navigate = useNavigate()
  const {allowedLocations} = useNavAccessContext()
  const {t} = useTranslation()
  const {performancePrefix} = useTranslationPrefix()

  const {children, id, name: areaName, isAllowed: showArea} = area

  const isCompleteAreaAllowed = useMemo(() => allowedLocations.includes(id), [allowedLocations, id])

  return showArea ? (
    <Box>
      <CollapseContentContainer
        onClick={() => handleOpen(id)}
        title={<LocationTitle title={areaName} />}
        action={
          isCompleteAreaAllowed ? (
            <Button
              sx={buttonOnBlueSx}
              onClick={() => navigate(generatePath(AREA_KPI_STATUS, {locationId: id}))}
              startIcon={<ArrowForward />}
              data-test-id={`show-area-${id}`}
            >
              {t(`${performancePrefix}.kilnStatus.actions.showArea`, {
                area: areaName
              })}
            </Button>
          ) : null
        }
        isOpen={isOpen}
        data-test-id={`chevron-button-${index}`}
      >
        <Collapse in={isOpen} timeout="auto">
          <Masonry columns={{xs: 1, sm: 1, md: 2, lg: 2, xl: 3}} spacing={3} sx={{width: 'auto'}}>
            {children?.map((region) => {
              if (region.type === HierarchyNodeType.Region && region.isAllowed) {
                return region.children.map(
                  (country) =>
                    country.isAllowed && (
                      <KilnStatusBox country={country} key={country.id} id={country.id} />
                    )
                )
              }
            })}
          </Masonry>
        </Collapse>
      </CollapseContentContainer>
    </Box>
  ) : null
}
