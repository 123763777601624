/**
 * NOTE:
 * This will be removed when we move to hierarchical data structure
 */
import {OtherRunningTimeTypes} from '@hconnect/common/types'
import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead
} from '@mui/material'
import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'
import {generatePath, useNavigate} from 'react-router-dom'

import {useActualOrTotalValue} from '../hooks/useActualValue'
import {useTranslationPrefix} from '../hooks/useTranslationPrefix'
import {PLANT_KPI_STATUS} from '../routing'
import {PerformanceDashboardEquipmentTypes, PlantEquipmentStatus} from '../types'

import {NoDataReasonLabel, ProductionVolumeLabel} from './ProductionVolumeLabel'

type KilnStatusTableOldProps = {
  kilnStatues: PlantEquipmentStatus[]
}

const getProductionContent = (
  showActualOrTotalValue: boolean,
  showActualValueOnly: boolean,
  plantEquipmentStatus: PlantEquipmentStatus,
  type: 'kiln' | 'mill'
) => {
  const value =
    type === 'mill'
      ? plantEquipmentStatus.actualCementProduction
      : plantEquipmentStatus.actualClinkerProduction

  const equipments =
    type === 'mill'
      ? plantEquipmentStatus?.equipments?.filter(
          (equipment) =>
            equipment.type === PerformanceDashboardEquipmentTypes.CEMENT_MILL ||
            equipment.type === PerformanceDashboardEquipmentTypes.RAW_MILL ||
            equipment.type === PerformanceDashboardEquipmentTypes.ROLLER_PRESS
        ) || []
      : plantEquipmentStatus?.equipments?.filter(
          (equipment) => equipment.type === PerformanceDashboardEquipmentTypes.KILN
        ) || []

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        rowGap: 0.5,
        columnGap: 3
      }}
    >
      {showActualValueOnly ? (
        value ? (
          <ProductionVolumeLabel
            key={plantEquipmentStatus.plant.plantId}
            status={OtherRunningTimeTypes.DEFAULT}
            volume={value}
          />
        ) : (
          <Typography variant="body1" color="secondary">
            -
          </Typography>
        )
      ) : (
        <>
          {showActualOrTotalValue && value ? (
            <ProductionVolumeLabel
              key={plantEquipmentStatus.plant.plantId}
              status={OtherRunningTimeTypes.DEFAULT}
              volume={value}
            />
          ) : (
            <>
              {equipments?.length > 0 &&
              plantEquipmentStatus.plant.pxTrendMetaData?.runinngTimesAvailable ? (
                equipments.map((equipment) => {
                  return (
                    <ProductionVolumeLabel
                      key={equipment.id}
                      status={equipment.status}
                      volume={equipment.productionVolume}
                      id={equipment.id}
                    />
                  )
                })
              ) : (
                <NoDataReasonLabel
                  isMill={type === 'mill'}
                  pxTrendMetaData={plantEquipmentStatus.plant.pxTrendMetaData}
                />
              )}
            </>
          )}
        </>
      )}
    </Box>
  )
}

export const KilnStatusTableOld: FC<KilnStatusTableOldProps> = ({kilnStatues}) => {
  const navigate = useNavigate()
  const {t} = useTranslation()
  const {performancePrefix} = useTranslationPrefix()
  const {showActualValueOnly, showActualOrTotalValue} = useActualOrTotalValue()

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left">{t(`${performancePrefix}.kilnStatus.label.plant`)}</TableCell>
            <TableCell align="left">{t(`${performancePrefix}.kilnStatus.label.kiln`)}</TableCell>
            <TableCell align="left">{t(`${performancePrefix}.kilnStatus.label.mills`)}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {kilnStatues?.map((kilnStatus, index) => (
            <TableRow
              key={index}
              data-test-id={`kiln-status-table-row-${index}`}
              onClick={() =>
                navigate(generatePath(PLANT_KPI_STATUS, {plantId: kilnStatus.plant.plantId}))
              }
              sx={{
                cursor: 'pointer'
              }}
            >
              <TableCell
                sx={{
                  paddingLeft: 2,
                  width: 150,
                  ...(kilnStatues.length - 1 === index
                    ? {borderBottom: 'none', paddingBottom: 0}
                    : {})
                }}
              >
                <Typography variant="subtitle1">{kilnStatus.plant.name}</Typography>
              </TableCell>
              <TableCell
                sx={{
                  ...(kilnStatues.length - 1 === index
                    ? {borderBottom: 'none', paddingBottom: 0}
                    : {})
                }}
              >
                {getProductionContent(
                  showActualValueOnly,
                  showActualOrTotalValue,
                  kilnStatus,
                  'kiln'
                )}
              </TableCell>
              <TableCell
                sx={{
                  ...(kilnStatues.length - 1 === index
                    ? {borderBottom: 'none', paddingBottom: 0}
                    : {})
                }}
              >
                {getProductionContent(
                  showActualValueOnly,
                  showActualOrTotalValue,
                  kilnStatus,
                  'mill'
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
