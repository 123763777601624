import {type Permission} from '@hconnect/apiclient'
import {Route} from 'react-router-dom'

import {Context} from '../shared/types/temp.types'

import {MyTasks} from './pages/MyTasks'
import {MY_TASKS} from './routing'

export const getMyTasksRoutes = ({
  context,
  permissions
}: {
  context: Context
  permissions: Permission[]
}) => {
  return [
    <Route
      key="my-tasks"
      path={MY_TASKS}
      element={<MyTasks context={context} permissions={permissions} />}
    />
  ]
}
