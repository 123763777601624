/**
 * NOTE:
 * This will be removed when we move to hierarchical data structure
 */
import {buttonOnBlueSx, CardBox} from '@hconnect/uikit/src/lib2'
import {ArrowForward} from '@mui/icons-material'
import Masonry from '@mui/lab/Masonry'
import {Box, Button, Collapse} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'

import {CollapseContentContainer} from '../containers/CollapseContentContainer'
import {useTranslationPrefix} from '../hooks/useTranslationPrefix'

import {CountryItemHeader, LocationTitle} from './CountryItemHeader'
import {KilnStatusTableOld} from './KilnStatusTableOld'

type CommonProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getAreaData?: (area) => any
}

type CollapseItemProps<T> = CommonProps & {
  handleOpen: (index: number) => void
  index: number
  isOpen: boolean
  area: T
  getUrl?: (locationId: string) => string
}

type KilnStatusBoxProps<T> = CommonProps & {
  area: T
  id: string | number
}

export function KilnStatusBoxOld<T>({area, id, getAreaData}: KilnStatusBoxProps<T>) {
  const areaData = getAreaData ? getAreaData(area) : area
  return (
    <CardBox data-test-id={`kiln-status-box-${id}`}>
      <Box display="flex" flexDirection="column">
        <CountryItemHeader countryData={areaData.locationData} />
        <KilnStatusTableOld kilnStatues={areaData.plantEquipmentStatuses} />
      </Box>
    </CardBox>
  )
}

export function CollapseItemOld<T>({
  area,
  handleOpen,
  index,
  isOpen,
  getAreaData,
  getUrl
}: CollapseItemProps<T>) {
  const navigate = useNavigate()
  const {t} = useTranslation()
  const areaData = getAreaData ? getAreaData(area) : area

  const {performancePrefix} = useTranslationPrefix()

  return (
    <Box>
      <CollapseContentContainer
        onClick={() => handleOpen(index)}
        title={<LocationTitle title={areaData.locationData.name} />}
        action={
          getUrl && (
            <Button
              sx={buttonOnBlueSx}
              onClick={() => navigate(getUrl(areaData.locationData.id as string))}
              startIcon={<ArrowForward />}
              data-test-id={`show-area-${areaData.locationData.id}`}
            >
              {t(`${performancePrefix}.kilnStatus.actions.showArea`, {
                area: areaData.locationData.name
              })}
            </Button>
          )
        }
        isOpen={isOpen}
        data-test-id={`chevron-button-${index}`}
      >
        <Collapse in={isOpen} timeout="auto">
          <Masonry columns={{xs: 1, sm: 1, md: 2, lg: 2, xl: 3}} spacing={3} sx={{width: 'auto'}}>
            {areaData.descendants?.map((area, index) => {
              const areaDetails = getAreaData ? getAreaData(area) : area
              if (areaDetails.locationData.type === 'region' && areaDetails.descendants.length) {
                return areaDetails.descendants?.map((country, descIndex) => (
                  <KilnStatusBoxOld
                    area={country}
                    key={`${areaDetails.locationData.id}-${index}-${descIndex}`}
                    id={`${areaDetails.locationData.id}-${index}-${descIndex}`}
                    getAreaData={getAreaData}
                  />
                ))
              } else
                return (
                  <KilnStatusBoxOld
                    area={area}
                    id={`${areaDetails.locationData.id}-${index}`}
                    key={`${areaDetails.locationData.id}-${index}`}
                    getAreaData={getAreaData}
                  />
                )
            })}
          </Masonry>
        </Collapse>
      </CollapseContentContainer>
    </Box>
  )
}
