import {type Permission} from '@hconnect/apiclient'
import {FlexPage} from '@hconnect/common/components/FlexPage'
import {useWidth} from '@hconnect/common/hooks/useWidth'
import {NotificationProvider} from '@hconnect/uikit'
import {useTranslation} from 'react-i18next'

import {Shell} from '../../shared/components/Shell'
import {PermissionsContextProvider} from '../../shared/hooks/usePermission'
import {Context} from '../../shared/types/temp.types'
import {EventsTableLayout} from '../layouts/EventsTableLayout'

type Props = {
  context: Context
  permissions: Permission[]
}

export const MyTasks: React.FC<Props> = ({context, permissions}) => {
  const {t} = useTranslation()
  const breakPoint = useWidth()
  const isSmall = breakPoint === 'xs' || breakPoint === 'sm'

  return (
    <PermissionsContextProvider initialState={permissions}>
      <NotificationProvider anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}>
        <Shell context={context} enableNotifications={true}>
          <FlexPage useViewportHeight={!isSmall} title={t('myTasks.pageTitle')}>
            <EventsTableLayout translationPrefix="myTasks" />
          </FlexPage>
        </Shell>
      </NotificationProvider>
    </PermissionsContextProvider>
  )
}
