import {
  EquipmentData,
  RunningTimeDto as RunningTime,
  StoppageDto as Stoppage,
  RunningTimeStoppage
} from '@hconnect/common/types'
import {DateRange} from '@hconnect/uikit'
import moment from 'moment-timezone'
import React, {useCallback, FC, useState} from 'react'

import {useConfigData} from '../../shared/hooks/useConfig'
import {usePlantTimezone} from '../../shared/hooks/useTimezone'
import {generateStoppageDefaultData} from '../helpers'
import {usePlantTimeRange} from '../hooks/useTimeRange'
import {StoppageEvent} from '../types'

import {StackedBarChart} from './charts'
import {StoppageView} from './performance/StoppageView'
import {PlantRunningTimes} from './PlantRunningTimes'

type PlantRunningTimesCardProps = {
  plantId: string
}

export const PlantRunningTimesCard: FC<PlantRunningTimesCardProps> = ({plantId}) => {
  const {from, to}: DateRange<Date> = usePlantTimeRange()
  const config = useConfigData()
  const timezone = usePlantTimezone()

  const [stoppageIdToPreview, setStoppageIdToPreview] = useState<string | undefined>(undefined)
  const [showCreateForm, setShowCreateForm] = useState<boolean>(false)
  const [stoppageToPreview, setStoppageToPreview] = useState<StoppageEvent | undefined>(undefined)
  const [stoppageData, setStoppageData] = useState<Partial<Stoppage> | undefined>(undefined)

  const handleStoppageSplit = (stoppage: Partial<Stoppage>) => {
    setStoppageIdToPreview(undefined)
    setStoppageToPreview(undefined)
    setShowCreateForm(true)
    setStoppageData(stoppage)
  }

  const handleClick = useCallback(
    (rt: RunningTime, equipment?: EquipmentData, stoppage?: RunningTimeStoppage) => {
      if (stoppage?.cockpitStoppageId) {
        setStoppageIdToPreview(stoppage.cockpitStoppageId)
      } else {
        setShowCreateForm(true)
        setStoppageToPreview(undefined)
        if (rt.begin && equipment && config?.defaultCurrency) {
          const defaultValue = generateStoppageDefaultData({
            runningTime: rt,
            equipment
          })
          setStoppageData(defaultValue)
        }
      }
    },
    [config?.defaultCurrency]
  )
  const getStackedBarChartComponent = useCallback(
    (equipment: EquipmentData, runningTimes: RunningTime[]) => {
      return (
        <StackedBarChart
          timeRange={{
            startDate: moment(from),
            endDate: moment(to)
          }}
          timezone={timezone}
          handleStoppageClick={(rt: RunningTime, stoppage?: RunningTimeStoppage) =>
            handleClick(rt, equipment, stoppage)
          }
          data={runningTimes}
          equipment={equipment}
          config={config}
        />
      )
    },
    [config, from, handleClick, to, timezone]
  )

  return (
    <>
      {stoppageIdToPreview || showCreateForm ? (
        <StoppageView
          plantId={plantId}
          showCreateForm={showCreateForm}
          stoppageData={stoppageData}
          stoppageToPreview={stoppageToPreview}
          setStoppageToPreview={setStoppageToPreview}
          setShowCreateForm={setShowCreateForm}
          stoppageIdToPreview={stoppageIdToPreview}
          setStoppageIdToPreview={setStoppageIdToPreview}
          handleStoppageSplit={handleStoppageSplit}
        />
      ) : (
        <PlantRunningTimes
          plantId={plantId}
          getStackedBarChartComponent={getStackedBarChartComponent}
        />
      )}
    </>
  )
}
