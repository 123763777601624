import {AppName} from '@hconnect/common/hproduce'
import {StatusWithCancellation} from '@hconnect/common/types'
import {AxiosError} from 'axios'
import {useQuery, UseQueryOptions} from 'react-query'

import {useAxios} from '../../shared/hooks/useApi'
import {PaginationResponse, Source, HProduceTask, HProduceTaskResponse} from '../types/task.types'

const QueryKey = 'myTasks'

const SERVER_APP_NAME_MAP: Record<Source, AppName> = {
  Cockpit: 'Cockpit',
  EasyPom: 'easyPOM'
}

const mapMyTasks = ({
  items,
  ...rest
}: PaginationResponse<HProduceTaskResponse>): PaginationResponse<HProduceTask> => ({
  ...rest,
  items: items.map(({completionStatus, source, ...rest}) => ({
    status: completionStatus.toLowerCase() as StatusWithCancellation,
    source: SERVER_APP_NAME_MAP[source] || 'HProduce',
    ...rest
  }))
})

export const useMyTasks = (
  limit: number,
  pageNumber: number,
  options?: UseQueryOptions<
    PaginationResponse<HProduceTaskResponse>,
    AxiosError,
    PaginationResponse<HProduceTask>
  >
) => {
  const axiosInstance = useAxios()

  return useQuery<
    PaginationResponse<HProduceTaskResponse>,
    AxiosError,
    PaginationResponse<HProduceTask>
  >(
    [QueryKey, limit, pageNumber],
    async () => {
      const response = await axiosInstance.get<PaginationResponse<HProduceTaskResponse>>(
        `/tasks/my-tasks?offset=${pageNumber * limit}&limit=${limit}`
      )
      return response.data || []
    },
    {
      select: (data) => mapMyTasks(data),
      ...options
    }
  )
}
