import {PageContainer} from '@hconnect/uikit/src/lib2'
import {Masonry} from '@mui/lab'
import {Grid, Typography} from '@mui/material'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate, useParams} from 'react-router'

import {checkFeatureFlag} from '../../shared/helpers/featureFlagHelpers'
import {useNavAccessContext} from '../../shared/hooks/useNavAccessContext'
import {CementIcon} from '../assets/CementIcon'
import {ClinkerIcon} from '../assets/ClinkerIcon'
import {CollapseItemOld, KilnStatusBoxOld} from '../components/CollapseItemOld'
import {DataContentWrapper} from '../components/DataContentWrapper'
import {DataInfo} from '../components/DataInfo'
import {DateTimeRangePicker} from '../components/DateTimeRangePicker'
import {ProductionBox} from '../components/ProductionBox'
import {StatusPageHeader} from '../containers/StatusPageHeader'
import {mapToFlatListWithKey} from '../helpers'
import {getProductionValue} from '../helpers/actualVsTotal.helpers'
import {useActualOrTotalValue} from '../hooks/useActualValue'
import {useLocationStatus} from '../hooks/useLocationStatus'
import {useStatusInitialTimeRange} from '../hooks/useStatusInitialTimeRange'
import {useTimeRange} from '../hooks/useTimeRange'
import {useTranslationPrefix} from '../hooks/useTranslationPrefix'
import {STATUS} from '../routing'
import {LocationStatus, PlantStatus, TimeRange} from '../types'

type PathParameter = {
  locationId: string
}

const getAreaData = (area) => {
  const {
    locationId,
    locationName,
    locationType,
    countryCode,
    descendants,
    plantStatuses,
    plantEquipmentStatuses
  } = area
  const newData = {
    locationData: {
      id: locationId,
      name: locationName,
      type: locationType,
      countryCode
    },
    plantStatuses,
    plantEquipmentStatuses: plantEquipmentStatuses ?? plantStatuses,
    descendants
  }
  return newData
}

export const AreaStatus: React.FC = () => {
  const {locationId} = useParams<PathParameter>()
  if (!locationId) throw new Error('Missing locationId prop')

  const {isLocationAllowed, allowMultipleLocations, getRedirectStatusURL} = useNavAccessContext()

  const navigate = useNavigate()
  useEffect(() => {
    if (!isLocationAllowed(locationId)) {
      const redirectUrl = getRedirectStatusURL()
      redirectUrl && navigate(redirectUrl)
    }
  }, [isLocationAllowed, locationId, navigate, getRedirectStatusURL])

  const {t} = useTranslation()
  const {from, to} = useTimeRange()
  const dateRange: TimeRange = {startDate: from.toJSON(), endDate: to.toJSON()}

  const timeRangeLoaded = useStatusInitialTimeRange()
  const {data, isLoading, error, refetch} = useLocationStatus(locationId, dateRange, {
    enabled: timeRangeLoaded
  })

  const [openItems, setOpenItems] = useState<number[]>([])

  const handleOpen = useCallback(
    (index: number) => {
      if (openItems.includes(index)) {
        const newOpenItems = openItems.filter((id) => id !== index)
        setOpenItems(newOpenItems)
      } else {
        setOpenItems([...openItems, index])
      }
    },
    [openItems]
  )

  const allowedPlants = useMemo<PlantStatus[]>(
    () =>
      mapToFlatListWithKey('descendants', data?.descendants || []).flatMap(
        (location) => location?.plantStatuses
      ),
    [data?.descendants]
  )

  const descendantsList = useMemo<LocationStatus[]>(
    () => data?.descendants || [],
    [data?.descendants]
  )

  const hasNonGrindingUnitPlant = allowedPlants?.some(
    (p) => !p.plant.pxTrendMetaData?.isGrindingUnit
  )

  const showWithRegion = useMemo(() => {
    const regions = descendantsList.filter(
      ({locationType, descendants}) => locationType === 'region' && descendants?.length
    )
    return regions.length > 0
  }, [descendantsList])

  useEffect(() => {
    setOpenItems([...Array(descendantsList.length).keys()])
  }, [descendantsList.length])

  const flags = useActualOrTotalValue()

  const clinkerProductionValue = getProductionValue({
    productionValueFlags: flags,
    actualValue: data?.actualClinkerProduction,
    totalValue: data?.totalClinker
  })
  const cementProductionValue = getProductionValue({
    productionValueFlags: flags,
    actualValue: data?.actualCementProduction,
    totalValue: data?.totalCement
  })

  const showGlobalKilnMillStatus = checkFeatureFlag('enableKilnMillGlobalStatus')
  const {performancePrefix} = useTranslationPrefix()

  const renderContent = () => (
    <>
      <StatusPageHeader
        filterComponent={<DateTimeRangePicker type="status" disableCustomSelect />}
        title={data?.locationName}
        backButtonProps={
          allowMultipleLocations() && showGlobalKilnMillStatus
            ? {
                targetName: t(`${performancePrefix}.goBackLabels.backToGlobal`),
                target: STATUS
              }
            : undefined
        }
        afterTitle={<DataInfo />}
      />

      {data && (
        <Grid container xs={12} spacing={2} sm={6} item sx={{marginBottom: 2}}>
          {hasNonGrindingUnitPlant && (
            <Grid item xs={12} sm={6}>
              <ProductionBox
                icon={<ClinkerIcon fontSize="small" sx={{marginRight: 1}} />}
                title={t(`${performancePrefix}.production.label.clinker`)}
                volume={clinkerProductionValue}
                unit={data.unitClinker}
                data-test-id="location-status-total-clinker"
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <ProductionBox
              icon={<CementIcon fontSize="small" sx={{marginRight: 1}} />}
              title={t(`${performancePrefix}.production.label.cement`)}
              volume={cementProductionValue}
              unit={data.unitCement}
              data-test-id="location-status-total-cement"
            />
          </Grid>
        </Grid>
      )}

      <Typography variant="h2" sx={{fontSize: 24, mb: 2, mt: 3, ml: 3}}>
        {t(`${performancePrefix}.locationStatus.label.plants`)}
      </Typography>

      {showWithRegion &&
        descendantsList.map((area, index) => (
          <CollapseItemOld<LocationStatus>
            key={`kiln-status-collapse-item-${index}`}
            handleOpen={handleOpen}
            isOpen={openItems.includes(index)}
            index={index}
            area={area}
            getAreaData={getAreaData}
          />
        ))}

      {!showWithRegion && (
        <Masonry columns={{xs: 1, sm: 2, lg: 3, xl: 4}} spacing={3} sx={{width: 'auto'}}>
          {descendantsList.map((area, index) => (
            <KilnStatusBoxOld area={area} key={index} id={index} getAreaData={getAreaData} />
          ))}
        </Masonry>
      )}
    </>
  )

  return (
    <PageContainer>
      <DataContentWrapper<LocationStatus>
        isLoading={isLoading}
        data={data}
        retryFunction={() => void refetch()}
        error={error}
        renderContent={renderContent}
      />
    </PageContainer>
  )
}
