import {customThemeConstants} from '@hconnect/uikit/src/lib2'

export const eventRowSx = (selected?: boolean) => ({
  cursor: 'pointer',
  backgroundColor: selected ? customThemeConstants().palette.contentSelected : 'none',
  '&:hover': {
    backgroundColor: selected
      ? customThemeConstants().palette.contentSelectedHover
      : customThemeConstants().palette.contentHover
  }
})
