import {BarForChart, BarForChartProps, RcfaLinks} from '@hconnect/common/components/runningTimes'
import {ConfigContext} from '@hconnect/common/hooks/ConfigContext'
import {mapRunningTimeDto, mapRunningTimes} from '@hconnect/common/mappers'
import {
  RunningTimeDto as RunningTime,
  DateTime,
  EquipmentData,
  RunningTimeStoppage
} from '@hconnect/common/types'
import {
  getEventStoppageType,
  getRunningTimeStoppageLabel,
  getStoppageDate
} from '@hconnect/common/utils'
import {timeFormatter, TimeZone} from '@hconnect/uikit'
import {Box} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {SystemStyleObject} from '@mui/system/styleFunctionSx/styleFunctionSx'
import moment, {Moment} from 'moment-timezone'
import React, {ReactNode, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router'

import {FeatureFlag} from '../../../shared/helpers/featureFlagHelpers'
import {getSystemTimezone} from '../../../shared/hooks/useTimezone'
import {useTranslationPrefix} from '../../hooks/useTranslationPrefix'

export type DateRange = {
  startDate: Moment
  endDate: Moment
}

function includeGapsInRunningTimes(timeRange: DateRange, data: RunningTime[]) {
  const runningTimesWithGaps: RunningTime[] = []
  if (data.length === 0) {
    return []
  }
  if (!moment(timeRange.startDate).isSame(moment(data[0].begin))) {
    const beginElement: RunningTime = {
      begin: timeRange.startDate.toISOString(),
      end: data[0].begin,
      runningTimeType: undefined
    }

    runningTimesWithGaps.push(beginElement)
  }

  data.forEach((rt, index) => {
    runningTimesWithGaps.push(rt)
    if (data[index + 1] && !moment(rt.end).isSame(moment(data[index + 1].begin))) {
      runningTimesWithGaps.push({
        begin: rt.end,
        end: data[index + 1].begin,
        runningTimeType: undefined
      })
    }
  })

  if (!moment(timeRange.endDate).isSame(moment(data[data.length - 1].end))) {
    const endElement: RunningTime = {
      begin: data[data.length - 1].end,
      end: timeRange.endDate.toISOString(),
      runningTimeType: undefined
    }

    runningTimesWithGaps.push(endElement)
  }

  return runningTimesWithGaps
}

function getBarPercentage(
  timeStart: Moment | undefined,
  timeEnd: Moment | undefined,
  totalRunningTime: number
) {
  if (!timeStart || !timeEnd || timeStart === timeEnd) {
    return '0%'
  }

  return ((timeEnd.diff(timeStart, 'minutes') / totalRunningTime) * 100).toString() + '%'
}

interface Props {
  timeRange: DateRange
  timezone?: string
  data: RunningTime[]
  sx?: SystemStyleObject<Theme>
  aboveBarComponent?: (rt: RunningTime) => ReactNode
  handleStoppageClick?: (rt: RunningTime, stoppage?: RunningTimeStoppage) => void | Promise<void>
  equipment: EquipmentData
  config?: ConfigContext<FeatureFlag>
}

export const TimeFormat: React.FC<{date: DateTime; timezone: TimeZone}> = ({date, timezone}) => {
  const {i18n} = useTranslation()
  const utcOffset = moment(date).tz(timezone).utcOffset()
  return <>{timeFormatter(date, i18n.language, utcOffset)}</>
}

type PathParameter = {
  plantId?: string
  equipmentId: string
}

export const StackedBarChart: React.FC<Props> = ({
  timeRange,
  timezone: contextTimezone,
  data,
  sx,
  handleStoppageClick,
  aboveBarComponent,
  equipment,
  config
}) => {
  const {plantId} = useParams<PathParameter>()
  const {performancePrefix} = useTranslationPrefix()
  const timezone = contextTimezone || getSystemTimezone()

  const totalRunningTime = useMemo(() => {
    return moment(timeRange.endDate).diff(moment(timeRange.startDate), 'minutes')
  }, [timeRange])

  const runningTimesWithGapsArray = useMemo(() => {
    const runningTimes = includeGapsInRunningTimes(timeRange, data)
    return mapRunningTimes(runningTimes, timezone)
  }, [timeRange, data, timezone])

  return (
    <>
      {runningTimesWithGapsArray && (
        <Box
          sx={{
            flex: '1',
            display: 'flex',
            alignItems: 'stretch',
            justifyContent: 'flex-start',
            borderRadius: 1,
            overflow: 'hidden'
          }}
        >
          {runningTimesWithGapsArray.map((rt, index) => {
            const rtDto = mapRunningTimeDto(rt)
            const barForChartProps: BarForChartProps = {
              timezone: timezone,
              percentage: getBarPercentage(rt.begin, rt.end, totalRunningTime),
              runningTime: rt,
              sx,
              aboveBarComponent:
                index === runningTimesWithGapsArray.length - 1
                  ? aboveBarComponent?.(rtDto)
                  : undefined,
              translationPrefix: performancePrefix
            }

            if (rt.stoppages) {
              return rt.stoppages.map((stoppage, stoppageIndex) => {
                const {from, to} = getStoppageDate(stoppage, rt)

                return (
                  <BarForChart
                    key={`${index}-${stoppageIndex}`}
                    {...barForChartProps}
                    stoppageIndex={stoppageIndex}
                    percentage={getBarPercentage(from, to, totalRunningTime)}
                    handleStoppageClick={
                      handleStoppageClick
                        ? (e) => {
                            e.stopPropagation()
                            void handleStoppageClick(rtDto, stoppage)
                          }
                        : undefined
                    }
                    tooltip={{
                      header:
                        config &&
                        getRunningTimeStoppageLabel(
                          {
                            kiln: config.kilnStoppageCodes,
                            cementMill: config.finishMillStoppageCodes,
                            rawMill: config.rawMillStoppageCodes
                          },
                          stoppage,
                          getEventStoppageType(equipment)
                        ),
                      footer: rt.rootCauseFailureAnalyses && plantId && (
                        <RcfaLinks
                          plantId={plantId}
                          rootCauseFailureAnalyses={rt.rootCauseFailureAnalyses}
                        />
                      )
                    }}
                  />
                )
              })
            }
            return (
              <BarForChart
                key={index}
                {...barForChartProps}
                handleStoppageClick={
                  handleStoppageClick
                    ? (e) => {
                        e.stopPropagation()
                        void handleStoppageClick(rtDto)
                      }
                    : undefined
                }
                tooltip={{
                  footer: rt.rootCauseFailureAnalyses && plantId && (
                    <RcfaLinks
                      plantId={plantId}
                      rootCauseFailureAnalyses={rt.rootCauseFailureAnalyses}
                    />
                  )
                }}
              />
            )
          })}
        </Box>
      )}
    </>
  )
}
