import {ExpertSystemWrapper} from '@hconnect/common/components/expertSystem'
import {isSameDayAcrossTimezones, DateRange, isSameMonthAcrossTimezones} from '@hconnect/uikit'
import {PageContainer} from '@hconnect/uikit/src/lib2'
import {Grid} from '@mui/material'
import moment from 'moment-timezone'
import React, {useEffect, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router'
import {generatePath, useNavigate} from 'react-router-dom'

import {useNavAccessContext} from '../../shared/hooks/useNavAccessContext'
import {usePlantTimezone} from '../../shared/hooks/useTimezone'
import {DataContentWrapper} from '../components/DataContentWrapper'
import {DateTimeRangePicker} from '../components/DateTimeRangePicker'
import {PerformanceSection} from '../components/performance/PerformanceSection'
import {PlantEntryPointDailyKpiSummary} from '../components/performance/PlantEntryPointDailyKpiSummary'
import {PlantActualVsPlanned} from '../components/PlantActualVsPlanned'
import {PlantRunningTimesCard} from '../components/PlantRunningTimesCard'
import {RcfaTable} from '../components/RcfaTable'
import {ScoreProgressSection} from '../components/ScoreProgressSection'
import {StatusPageHeader} from '../containers/StatusPageHeader'
import {useExpertSystemData} from '../hooks/useExpertSystemData'
import {usePlantStatus} from '../hooks/usePlantStatus'
import {usePlantTimeRange} from '../hooks/useTimeRange'
import {useTranslationPrefix} from '../hooks/useTranslationPrefix'
import {GLOBAL_KILN_STATUS, LOCATION_KPI, LOCATION_KPI_STATUS} from '../routing'
import {LocationType, PlantStatus as PlantStatusData, TimeRange} from '../types'

import {PerformanceStatusWrapper} from './PerformanceStatusWrapper'

const getLocationTranslationKeys = function (prefix: string): Record<LocationType, string> {
  return {
    country: `${prefix}.goBackLabels.backToCountry`,
    area: `${prefix}.goBackLabels.backToArea`,
    region: `${prefix}.goBackLabels.backToRegion`,
    plant: `${prefix}.plantStatus.label.backToPlant`
  }
}

type PathParameter = {
  plantId: string
}

type Props = {
  performance?: boolean
}

export const PlantStatus: React.FC<Props> = ({performance}) => {
  const {plantId} = useParams<PathParameter>()
  if (!plantId) throw new Error('Missing plantId prop')
  const navigate = useNavigate()

  const backButtonLocation = performance ? LOCATION_KPI : LOCATION_KPI_STATUS

  const {isPlantAllowed, allowMultiplePlants, allowedLocations} = useNavAccessContext()

  useEffect(() => {
    if (!isPlantAllowed(plantId)) {
      navigate(GLOBAL_KILN_STATUS)
    }
  }, [isPlantAllowed, navigate, plantId])

  const {t} = useTranslation()
  const {performancePrefix} = useTranslationPrefix()
  const timezone = usePlantTimezone()
  const {from, to}: DateRange<Date> = usePlantTimeRange()
  const dateRange: TimeRange = {startDate: from.toJSON(), endDate: to.toJSON()}
  const isSameDayRange = isSameDayAcrossTimezones(moment.utc(from), moment.utc(to), timezone)
  const plantStatusQuery = usePlantStatus(plantId, dateRange)
  const {data, isLoading, error, refetch} = plantStatusQuery
  const locationTranslationKeys = useMemo(
    () => getLocationTranslationKeys(performancePrefix),
    [performancePrefix]
  )

  const isExpertSystemEnabled = isSameMonthAcrossTimezones(
    moment.utc(from),
    moment.utc(to),
    timezone
  )

  const {
    data: expertSystemEquipments,
    error: expertSystemEquipmentError,
    isFetching: expertSystemEquipmentsFetching,
    isLoading: expertSystemEquipmentsLoading
  } = useExpertSystemData(plantId, dateRange, {
    enabled: isExpertSystemEnabled
  })
  const parentLocationIsAccessible =
    data?.plant.parentLocationId && allowedLocations.includes(data?.plant.parentLocationId)

  const expertSystemEquipmentsFetchingOrLoading =
    expertSystemEquipmentsFetching || expertSystemEquipmentsLoading
  const showBackButton = allowMultiplePlants() && parentLocationIsAccessible

  const renderContent = () => {
    const header = (
      <StatusPageHeader
        countryCode={data?.plant?.countryCode}
        title={data?.plant.name || ''}
        plantId={plantId}
        showDashboardLink
        filterComponent={<DateTimeRangePicker type="all" />}
        backButtonProps={
          showBackButton
            ? {
                targetName: data?.plant.parentLocationType
                  ? t(locationTranslationKeys[data.plant.parentLocationType])
                  : t(`${performancePrefix}.plantStatus.label.back`),
                target: generatePath(backButtonLocation, {
                  locationId: data?.plant.parentLocationId || null
                })
              }
            : undefined
        }
      />
    )

    if (isSameDayRange) {
      return (
        <>
          {header}
          <DailyPlantStatusLayout
            general={<PlantEntryPointDailyKpiSummary plantId={plantId} />}
            runningTimes={<PlantRunningTimesCard plantId={plantId} />}
            expertSystem={
              <ExpertSystemWrapper
                isSameMonth={isExpertSystemEnabled}
                expertSystemEquipments={expertSystemEquipments}
                translationPrefix={performancePrefix}
                isLoading={expertSystemEquipmentsFetchingOrLoading}
                error={expertSystemEquipmentError}
              />
            }
            scoreProgress={<ScoreProgressSection />}
            rcfa={<RcfaTable plantId={plantId} />}
          />
        </>
      )
    }

    return (
      <>
        {header}
        <PlantStatusLayout
          janusKPIs={
            data ? (
              <PerformanceStatusWrapper
                metricsComponent={{component: PlantActualVsPlanned, props: {plantStatusQuery}}}
                type="all"
                timezone={timezone}
              />
            ) : null
          }
          performance={<PerformanceSection plantId={plantId} />}
          expertSystem={
            <ExpertSystemWrapper
              isSameMonth={isExpertSystemEnabled}
              expertSystemEquipments={expertSystemEquipments}
              translationPrefix={performancePrefix}
              isLoading={expertSystemEquipmentsFetchingOrLoading}
              error={expertSystemEquipmentError}
            />
          }
          scoreProgress={<ScoreProgressSection />}
          rcfa={<RcfaTable plantId={plantId} />}
        />
      </>
    )
  }

  return (
    <PageContainer>
      <DataContentWrapper<PlantStatusData>
        isLoading={isLoading}
        data={data}
        retryFunction={() => void refetch()}
        error={error}
        renderContent={renderContent}
      />
    </PageContainer>
  )
}

type LayoutProps = {
  janusKPIs?: React.ReactNode
  general?: React.ReactNode
  rcfa?: React.ReactNode
  performance?: React.ReactNode
  scoreProgress?: React.ReactNode
  expertSystem?: React.ReactNode
  runningTimes?: React.ReactNode
}

const DailyPlantStatusLayout = ({
  general,
  runningTimes,
  expertSystem,
  scoreProgress,
  rcfa
}: LayoutProps) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Grid container spacing={2} direction={'column'}>
          <Grid item>{general}</Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container spacing={2} direction={'column'}>
          <Grid item>{runningTimes}</Grid>
          <Grid item>{expertSystem}</Grid>
          <Grid item>{rcfa}</Grid>
          <Grid item>{scoreProgress}</Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

const PlantStatusLayout = ({
  janusKPIs,
  performance,
  expertSystem,
  scoreProgress,
  rcfa
}: LayoutProps) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Grid container direction={'column'} spacing={2}>
          <Grid item>{janusKPIs}</Grid>
          <Grid item>{scoreProgress}</Grid>
          <Grid item>{expertSystem}</Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container direction={'column'} spacing={2}>
          <Grid item>{rcfa}</Grid>
          <Grid item>{performance}</Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
